import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ORBApi from '../../services/ORBApi';
import { ORBTableBodyRow, ORBTableHeaderCell } from '../../components/ORBTable';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MicIcon from '@mui/icons-material/Mic';
import AlbumIcon from '@mui/icons-material/Album';
import PianoIcon from '@mui/icons-material/Piano';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { CheckCircle } from '@mui/icons-material';
import DocumentFormatter from '../../utils/DocumentFormatter';


const TrackDetails = ({track_id, onClickClose}) => {
	const [track, setTrack] = useState(
		{isrc: "", title: "", duration: "", record_date: "", publish_date: "", genre: "", classification: "", status: "",
		songwriters: [], main_artists: [], music_publishers: [], musicians: [], digital_contributions: []}
	);

	useEffect(() => {
		if (track_id) {
			ORBApi.loadTrack(track_id)
			.then((data) => {
				setTrack(data);
			})
		}
	}, [track_id]);

	const getContributorName = (contributor) => {
		let name = contributor.name;
		if (contributor.artist_name)
			name = `${name} (${contributor.artist_name})`;
		return name;
	}
	const styles = {
		pageTitle: {
			textAlign: 'center',
			fontWeight: 'bold',
			marginBottom: 5
		},
		accordionSummary: {
			backgroundColor: '#D9D9D9', 
			color: '#000',
			fontWeight: 'bold',
			borderRadius: 5
		},
		accordionIcon: {
			marginRight: 5
		},
		tableCell: {
			fontSize: '9pt'
		}
	}
	return (
		<Container variant='modalPage'>
			 	<Box sx={{textAlign:'right', paddingTop: 2, cursor: 'pointer'}}>
					<CloseRoundedIcon fontSize='large' color='primary' onClick={()=> {onClickClose();}}/>
				</Box>
				<Typography variant='h2' color='primary' sx={styles.pageTitle}>DETALHES</Typography>

			<Grid container spacing={2}>
				<Grid item xs={3}>
					<TextField fullWidth label="ISRC" value={track.isrc} inputProps={{readOnly:true}}/>
				</Grid>
				<Grid item xs={3}>
					<TextField fullWidth label="Status" value={track.status} inputProps={{readOnly:true}}/>
				</Grid>
				<Grid item xs={12}>
					<TextField fullWidth label="Título"  value={track.title} inputProps={{readOnly:true}}/>
				</Grid>
				<Grid item xs={3}>
					<TextField fullWidth label="Gênero" value={track.genre} inputProps={{readOnly:true}}/>
				</Grid>
				<Grid item xs={3}>
					<TextField fullWidth label="Classificação" value={track.classification} inputProps={{readOnly:true}}/>
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth label="Duração" value={track.duration} inputProps={{readOnly:true}}/>
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth label="Data de gravação" value={track.record_date} inputProps={{readOnly:true}}/>
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth label="Data de publicação" value={track.publish_date} inputProps={{readOnly:true}}/>
				</Grid>
			</Grid>
			<Grid container spacing={1} sx={{marginTop: 3}}>
				<Grid item xs={6}>
					<Accordion>
						<AccordionSummary sx={styles.accordionSummary}>
							<MenuBookIcon sx={styles.accordionIcon} color="#FFF" size='large' />
							<Typography>Autores/Editoras</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<ORBTableHeaderCell sx={styles.tableCell}>Documento</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Nome</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Editora</ORBTableHeaderCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{track.songwriters.map((sw) => {
										let name = getContributorName(sw);
										return (
											<ORBTableBodyRow>
												<TableCell sx={styles.tableCell}>{DocumentFormatter.format(sw.document)}</TableCell>
												<TableCell sx={styles.tableCell}>{name}</TableCell>
												<TableCell sx={styles.tableCell}>{sw.is_company ? <CheckCircle/>: null}</TableCell>
											</ORBTableBodyRow>
										)
									})}
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid item xs={6}>
					<Accordion>
						<AccordionSummary sx={styles.accordionSummary}>
							<MicIcon sx={styles.accordionIcon} color="#FFF" size='large' />
							<Typography>Intérpretes</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<ORBTableHeaderCell sx={styles.tableCell}>Documento</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Nome</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Endereço</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>%</ORBTableHeaderCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{track.main_artists.map((ma) => {
										let name = getContributorName(ma);
										return (
											<ORBTableBodyRow>
												<TableCell sx={styles.tableCell}>{DocumentFormatter.format(ma.document)}</TableCell>
												<TableCell sx={styles.tableCell}>{name}</TableCell>
												<TableCell sx={styles.tableCell}>{ma.address}</TableCell>
												<TableCell sx={styles.tableCell}>{ma.share}</TableCell>
											</ORBTableBodyRow>
										)
									})}
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid item xs={6}>
					<Accordion>
						<AccordionSummary sx={styles.accordionSummary}>
							<AlbumIcon sx={styles.accordionIcon} color="#FFF" size='large' />
							<Typography>Produtores Fonográficos</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<ORBTableHeaderCell sx={styles.tableCell}>Documento</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Nome</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Endereço</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>%</ORBTableHeaderCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{track.music_publishers.map((mp) => {
										let name = getContributorName(mp);
										return (
											<ORBTableBodyRow>
												<TableCell sx={styles.tableCell}>{DocumentFormatter.format(mp.document)}</TableCell>
												<TableCell sx={styles.tableCell}>{name}</TableCell>
												<TableCell sx={styles.tableCell}>{mp.address}</TableCell>
												<TableCell sx={styles.tableCell}>{mp.share}</TableCell>
											</ORBTableBodyRow>
										)
									})}
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid item xs={6}>
					<Accordion>
						<AccordionSummary sx={styles.accordionSummary}>
							<PianoIcon sx={styles.accordionIcon} color="#FFF" size='large' />
							<Typography>Músicos</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<ORBTableHeaderCell sx={styles.tableCell}>Documento</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Nome</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Endereço</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Instrumentos</ORBTableHeaderCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{track.musicians.map((mu) => {
										let name = getContributorName(mu);
										return (
											<ORBTableBodyRow>
												<TableCell sx={styles.tableCell}>{DocumentFormatter.format(mu.document)}</TableCell>
												<TableCell sx={styles.tableCell}>{name}</TableCell>
												<TableCell sx={styles.tableCell}>{mu.address}</TableCell>
												<TableCell sx={styles.tableCell}>{mu.instruments.join(" | ")}</TableCell>
											</ORBTableBodyRow>
										)
									})}
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid item xs={6}>
					<Accordion>
						<AccordionSummary sx={styles.accordionSummary}>
							<SmartDisplayIcon sx={styles.accordionIcon} color="#FFF" size='large' />
							<Typography>Participação Digital</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<ORBTableHeaderCell sx={styles.tableCell}>Documento</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Nome</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>Endereço</ORBTableHeaderCell>
											<ORBTableHeaderCell sx={styles.tableCell}>%</ORBTableHeaderCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{track.digital_contributions.map((dc) => {
										let name = getContributorName(dc);
										return (
											<ORBTableBodyRow>
												<TableCell sx={styles.tableCell}>{DocumentFormatter.format(dc.document)}</TableCell>
												<TableCell sx={styles.tableCell}>{name}</TableCell>
												<TableCell sx={styles.tableCell}>{dc.address}</TableCell>
												<TableCell sx={styles.tableCell}>{dc.share}</TableCell>
											</ORBTableBodyRow>
										)
									})}
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				</Grid>
			</Grid>
		</Container>
	)
}

export default TrackDetails;