import React, {useState} from 'react';
import { TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

export default function DebouncedTextField({onChange, label, style, delay, numeric, initialValue}) {
  
    const [value, setValue] = useState(initialValue ? initialValue : "");
    const debounced = useDebouncedCallback((value) => {
        onChange(value);
    }, delay ? delay : 500);

    const onTextChange = (e) => {
        let newValue = e.target.value;
        if (numeric) {
            newValue = e.target.value.replace(/\D+/g, '');
        }
        if (newValue !== value) {
            setValue(newValue);
            debounced(newValue);
        }
    }
    return (
        <TextField
        fullWidth
        variant="outlined"
        label={label}
        style={style}
        value={value}
        onChange={onTextChange}
        />
    );
}
