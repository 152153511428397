import { TableCell, TableRow } from '@mui/material';
import {styled} from '@mui/material/styles';

const ORBTableHeaderCell = styled(TableCell)(({theme}) => (
	theme.custom.table.headerCell
));

const ORBTableBodyRow = styled(TableRow)(({theme}) => (
	{
		height: 40,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		},
		'&:hover': {
			backgroundColor: theme.palette.action.focus
		}
	}
));


export {ORBTableHeaderCell, ORBTableBodyRow};