import React from 'react';
import ORBApi from '../services/ORBApi';

const SessionContext = React.createContext();
const SessionProvider = ({children}) => {

	const [sessionData, SetSessionData] = React.useState(null);

	const setupSession = async() => {
		return ORBApi.getSessionData()
		.then((data) => {
			SetSessionData(data);
			return true;
		})
		.catch(()=> { return false;})
	}

	return (<SessionContext.Provider value={{sessionData, setupSession}}>{children}</SessionContext.Provider>)
}

export default SessionContext;
export {SessionProvider};