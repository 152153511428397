import React, {useState} from 'react';
import StatusSelect from '../../components/StatusSelect';
import { ToastContainer} from 'react-toastify';
import { FormControl} from "@mui/material";

import DebouncedTextField from '../../components/DebouncedText';

const ActionBar = ({onFiltersChange, initialSearch}) => {

    const [filters, setFilters] = useState({status: "", searchText: initialSearch ? initialSearch : "", document: ""})
	const accountStatusList = [{value:'pending', name:'Aguardando aprovação'}, {value:'active', name:'Aprovada'}, 
                                {value:'no_affiliation', name: 'Sem filiação'}, {value:'affiliation_in_progress', name:'Filiação em andamento'}]
	

    /**
     * Throw the onFiltersChange event and update the current filter state
     * @param {string} status 
     * @param {string} searchText 
     * @param {string} document 
     */
	const throwFilterEvent = (status, searchText, document) => {
		let newFilter = {status, searchText, document};
		setFilters(newFilter);
		onFiltersChange(newFilter);
	}

	const styles = {
		filterItem: {
			marginRight: 30
		}
	}
	return (

		<div className="page-actionbar-container">
			<ToastContainer />
			<fieldset className='fields-container'>
				<legend className='fields-legend'>Filtros</legend>
				<StatusSelect style={styles.filterItem} data={accountStatusList} allowAll={true} onChange={(value) => {throwFilterEvent(value, filters.searchText, filters.document)}}/>
				<FormControl className='text-input' style={styles.filterItem}>
					<DebouncedTextField label='Nome ou email' initialValue={filters.searchText} value={filters.searchText} onChange={(value)=> {throwFilterEvent(filters.status, value, filters.document)}}/>
				</FormControl>
				<FormControl className='text-input' style={styles.filterItem}>
					<DebouncedTextField style={styles.filterItem} label='Documento (CPF/CNPJ)' value={filters.document} delay={800}
                        numeric={true} onChange={(value)=> {throwFilterEvent(filters.status, filters.searchText, value)}} />
				</FormControl>
			</fieldset>
		</div>
	)
}

export default ActionBar;