
export default class DocumentFormatter {

	static format(doc) {
        if (!doc) return "";
		doc = doc.replace(/\D/g, ""); // removes everything but digits
		if (doc.length < 12)
			return doc.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
		return doc.replace(/^(\d{1,2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
	}
}