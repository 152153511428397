import React, {useState} from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';


const StatusSelect = ({allowAll, onChange, style, data}) => {

	const [value, setValue] = useState('');
	const styles = {
		select : {
			fontSize: '10pt',
			width: 200
		},
		item: {
			fontSize: '10pt'
		},
		label: {
			fontSize: '10pt'
		}
	};
	return (
		<FormControl style={style}>
			<InputLabel style={styles.label} id='filter-status-label'>Status</InputLabel>
			<Select id="filter-status" labelId='filter-status-label' label="Status" variant="outlined"  value={value}
				style={styles.select} onChange={(event) => {setValue(event.target.value);onChange(event.target.value)}}>
					{allowAll ? <MenuItem style={styles.item} value=""><em>Todos</em></MenuItem> : null}
					{(data) ?
						data.map(item => {
						return <MenuItem style={styles.item} key={item.value} value={item.value}>{item.name}</MenuItem>
					}):null
					}
			</Select>
		</FormControl>
	)

}

export default StatusSelect;