import React, {useState, useEffect} from 'react';
import { BorderColorRounded, CheckCircleRounded, CloseRounded, DoneRounded, PersonRounded, StoreRounded, ThumbUpRounded } from '@mui/icons-material';
import { CircularProgress, Dialog, DialogTitle, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import {useLocation} from 'react-router-dom';

import DocumentFormatter from '../../utils/DocumentFormatter';
import Loading from '../../components/Loading';
import ORBAppBar from '../../components/ORBAppBar';
import { ORBTableBodyRow, ORBTableHeaderCell } from '../../components/ORBTable';
import AssociationSelect from '../../components/AssociationSelect';
import ORBApi from '../../services/ORBApi';
import ActionBar from './ActionBar';

/**
 * Component to render user row of the table
 * 
 * @param {object} user User information 
 * @returns 
 */
const UserTableRow = ({user}) => {
    // list of account statuses
	const accountStatusList = {'PendingValidation': 'Aguardando aprovação', 'Active': 'Aprovada', 'PendingAffiliation':'Sem filiação', 'AffiliationInProgress': 'Filiação em andamento'};
    // approving processing flag
	const [isApproving, setIsApproving] = useState(false);
    // personal document loading flag
	const [loadingPersonalDocument, setLoadingPersonalDocument] = useState(false);
    // personal document back loading flag
	const [loadingPersonalDocumentBack, setLoadingPersonalDocumentBack] = useState(false);
    // company document loading flag
	const [loadingCompanyDocument, setLoadingCompanyDocument] = useState(false);
    // show control to edit association
    const [showAssociationCtrl, setShowAssociationCtrl] = useState(false);
    // Association editing process flag
    const [editingAssociation, setEditingAssociation] = useState(false);
    // User information to be shown on the component, it is using on state to help render just updated info
    const [userInfo, setUserInfo] = useState(user)
    // Form for user to confirm the ECAD code of account
    const [showEcadCodeForm, setShowEcadCodeForm] = useState(false);
    // Value of ECAD code confirmation
    const [ecadCode, setEcadCode] = useState(user.ecad_code ? user.ecad_code : '');


    /**
     * Approve the user account
     */
	const doApproveAccount = ()=> {
		setIsApproving(true);
		ORBApi.approveAccount(user.orb_account_id, ecadCode)
		.then(()=> {
            setUserInfo({...userInfo, ecad_code: ecadCode, status: 'Active'});
			toast.success("Conta aprovada!");
		})
		.catch((error) => {
			console.log(error);
			toast.error("Falha ao aprovar conta.")
		})
		.finally(()=>{setIsApproving(false);})
	}

    /**
     * Open a new window and display the personal document 
     * @param {boolean} show_back True if should show the back of the document
     * */
	const showPersonalDocument = (show_back = false) => {
        if (show_back)
            setLoadingPersonalDocumentBack(true);
        else
            setLoadingPersonalDocument(true);
		ORBApi.getUserPersonalDocument(user.orb_account_id, show_back)
		.then((data)=> {
			openDocumentWindow(data.document_url);
		})
		.catch(() => {
			toast.error("Houve um erro ao tentar abrir o documento.")
		})
		.finally(() => { 
            if (show_back)
                setLoadingPersonalDocumentBack(false);
            else
                setLoadingPersonalDocument(false); 
        })
	}

    /**
     * Open a new window and display the company document
     */
	const showCompanyDocument = () => {
		setLoadingCompanyDocument(true);
		ORBApi.getUserCompanyDocument(user.orb_account_id)
		.then((data) => {
			openDocumentWindow(data.document_url);
		})
		.catch(() => {
			toast.error("Houve um erro ao tentar abrir o documento.")
		})
		.finally(() => { setLoadingCompanyDocument(false); })
	}

    /**
     * Open a new window and display the URL
     * @param {string} url URL to be displayed
     */
	const openDocumentWindow = (url) => {
		window.open(url,'_blank', "width=600px, height=800px");
	}

    /***
     *  Update the association of the user
     * @param {string} new_association Association name
     */
    const updateAssociation = (new_association) => {
        setEditingAssociation(true);
    ORBApi.updateUserAssociation(user.orb_account_id, new_association)
    .then((data)=>{ 
        setUserInfo({...userInfo, association: data.association, status: data.status});
    })
    .finally(()=> {
        setEditingAssociation(false);
        setShowAssociationCtrl(false);
    });
    }

	const styles = {
		actionIcon: {
			cursor: 'pointer',
			marginRight: 20,
		},
		inactiveActionIcon: {
            cursor: 'default',
            marginRight: 20,
		},
        dialogControls: {
            display: 'inline-block',
            cursor: 'pointer',
            margin: '10px 20px'
        }
	}
	return (
        <>
		<ORBTableBodyRow key={userInfo.orb_account_id}>
			<TableCell>{userInfo.name}</TableCell>
			<TableCell>{userInfo.email}</TableCell>
			<TableCell>{userInfo.ecad_code}</TableCell>
			<TableCell>{DocumentFormatter.format(userInfo.document)}</TableCell>
			<TableCell>{(userInfo.association) ? userInfo.association : 
                (showAssociationCtrl) ? 
                    (editingAssociation) ? <CircularProgress style={{marginRight: 20}} color="secondary" size={25}/> : <AssociationSelect onChange={(value)=> {updateAssociation(value)}}/>  :
                <BorderColorRounded style={{cursor:'pointer'}} titleAccess='Informar associação' color='secondary' onClick={()=>{setShowAssociationCtrl(true);}}/>}
            </TableCell>
			<TableCell>{accountStatusList[userInfo.status]}</TableCell>
			<TableCell>
				{loadingPersonalDocument ? <CircularProgress style={{marginRight: 20}} color="secondary" size={25} /> :
					<PersonRounded style={styles.actionIcon} titleAccess='Ver Documento Pessoal (Frente)' color={userInfo.has_personal_document ? 'secondary' : 'disabled'} 
						onClick={() => {showPersonalDocument();}}/>
				}
				{loadingPersonalDocumentBack ? <CircularProgress style={{marginRight: 20}} color="secondary" size={25} /> :
					<PersonRounded style={styles.actionIcon} titleAccess='Ver Documento Pessoal (Verso)' color={userInfo.has_personal_verse_document ? 'secondary' : 'disabled'} 
						onClick={() => {showPersonalDocument(true);}}/>
				}
				{loadingCompanyDocument ? <CircularProgress style={{marginRight: 20}} color="secondary" size={25} /> :
					<StoreRounded style={styles.actionIcon} titleAccess='Ver Documento CNPJ' color={userInfo.has_company_document ? 'secondary' : 'disabled'}
						onClick={showCompanyDocument}/>
				}
                {(userInfo.status === 'Active') ?
                    <CheckCircleRounded style={{...styles.actionIcon, cursor:'default'}} titleAccess='Conta aprovada' htmlColor='#008800'/>
                    : (userInfo.status === 'PendingValidation' || userInfo.status === 'AffiliationInProgress') ?
                        isApproving ? <CircularProgress  style={{marginRight: 20}} color='secondary' size={25}/>:
                            <ThumbUpRounded style={styles.actionIcon} titleAccess='Aprovar conta' color='secondary'
                                onClick={()=> {setShowEcadCodeForm(true);}}/>
                        :
                        <ThumbUpRounded style={styles.inactiveActionIcon} titleAccess='Aprovar conta' color='disabled' />
                }
			</TableCell>
		</ORBTableBodyRow>
        <Dialog open={showEcadCodeForm}>
            <DialogTitle>Confirme o código ECAD para aprovação da conta</DialogTitle>
            <div style={{textAlign: 'center', marginBottom: '10px'}}>
                <TextField variant='outlined' label='Código ECAD' style={{width:200, display:'inline-block'}} value={ecadCode}
                onChange={(e)=> {if (/^[0-9]+$/.test(e.target.value)) setEcadCode(e.target.value);}}/>
                <DoneRounded color='secondary' style={styles.dialogControls} fontSize='large' onClick={()=>{doApproveAccount();setShowEcadCodeForm(false);}}/>
                <CloseRounded color='secondary' style={styles.dialogControls} fontSize='large' onClick={()=> {setEcadCode('');setShowEcadCodeForm(false)}}/>
            </div>
        </Dialog>
        </>
	)
}

/**
 * Page component
 */
const UserList = () => {

    const {search} = useLocation();
    const params = new URLSearchParams(search);
	const [userList, setUserList] = useState({total:0, current_page:1, items:[]});
	const [filters, setFilters] = useState({status: null, searchText: params.get("name"), document: null, page:1});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
        console.log(filters);
		ORBApi.listUsers(filters.status, filters.searchText, filters.document, filters.page)
		.then((data) => {
			setUserList(data);
		})
		.finally(()=> {setLoading(false);})
	}, [filters])

	return (
		<>
			<ORBAppBar />
			<ActionBar initialSearch={filters.searchText} onFiltersChange={(newFilters)=> {setFilters({...newFilters, page:1})}}/>

			{loading ? 
				<Loading text="CARREGANDO USUÁRIOS ..." />
			:
				<>
				<TableContainer sx={{maxHeight: 500}}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<ORBTableHeaderCell>Nome</ORBTableHeaderCell>
								<ORBTableHeaderCell>Email</ORBTableHeaderCell>
								<ORBTableHeaderCell>Código ECAD</ORBTableHeaderCell>
								<ORBTableHeaderCell>Documento</ORBTableHeaderCell>
								<ORBTableHeaderCell>Associação</ORBTableHeaderCell>
								<ORBTableHeaderCell>Status</ORBTableHeaderCell>
								<ORBTableHeaderCell></ORBTableHeaderCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{userList.items.map((user) => {
								return (
									<UserTableRow key={user.orb_account_id} user={user} />
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<Pagination count={Math.ceil(userList.total/10)} page={userList.current_page} onChange={(event,page) => {setFilters({...filters, page:page})}}/>
				</>
			}
		</>
	)
}

export default UserList;