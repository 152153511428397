import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const AssociationSelect = ({onChange, style}) => {

    const [value, setValue] = React.useState('');
    const data = ["ABRAMUS","AMAR", "ASSIM",  "SBACEM", "SICAM", "SOCINPRO", "UBC"];
    return (
        <FormControl style={style}>
            <InputLabel id="association-label" style={{}}>Associação</InputLabel>
            <Select labelId='association-label' label="Associação" variant='outlined' value={value}
            style={{width: 200}} onChange={(event) => {
                    setValue(event.target.value);
                    if (onChange) onChange(event.target.value);
                }}>
                {data.map(item => {
                   return <MenuItem style={{}} value={item} key={item}>{item}</MenuItem> 
                })}
            </Select>
        </FormControl>
    )
}

export default AssociationSelect;