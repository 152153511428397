 import axios from "axios"; 
 import config from "../config";

 axios.defaults.baseURL = config.api_base_url;
export default class ORBApi {

 static _doGet(url, responseType, abortSource) {
    axios.defaults.headers.common['x-orb-auth'] = localStorage.getItem('auth_token');
    let requestOptions = {};
    if (responseType) requestOptions.responseType = responseType;
    if (abortSource) requestOptions.cancelToken = abortSource.token;
    return axios
      .get(url, requestOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.replace('/');
        } 
        throw error;
      });
  }      

  static _doPost(url, data, contentType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    if (!contentType) contentType = 'application/json';
    return axios
      .post(url, data, {
        headers: {
          'content-type': contentType,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  static _doPatch(url, data, contentType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    if (!contentType) contentType = 'application/json';
    return axios
      .patch(url, data, {
        headers: {
          'content-type': contentType,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  static getSessionData() {
    return ORBApi._doGet("/admin/sessions/me");
  }
  // Update tracks status
  static updateTrackStatus(tracks) {
    return ORBApi._doPatch("/admin/tracks", tracks);
  }

  // Load single track
  static loadTrack(track_id) {
    return ORBApi._doGet(`/private/tracks/${track_id}`);
  }
  // Load tracks
  static loadTracks(searchFilter, status, page) {
    return ORBApi._doGet(`/admin/tracks?page=${page}&filter=${searchFilter}&status=${status}`);
  }

  // Download tracks
  static downloadTracks(status) {
    return ORBApi._doGet(`/admin/tracks/export_file?status=${status}`, "blob");
  }

  /**
   * List users 
   * @param {string} status Status to filter by
   * @param {string} search_filter String to be used on filter
   * @param {string} document Document to be used on filter
   * @param {number} page Page of result to show
   * @returns {Promise}
   */
  static listUsers(status, search_filter, document, page) {
    let url = `/admin/users?page=${page}`;
    if (status) url += `&status=${status}`;
    if (search_filter) url += `&filter=${search_filter}`;
    if (document) url += `&document=${document}`;

    return ORBApi._doGet(url)
  }

  /**
   * Approve the account adding or confirming the ECAD code
   * @param {number} id 
   * @param {string} ecad_code 
   * @returns {Promise}
   */
  static approveAccount(id, ecad_code) {
    return ORBApi._doPost(`/admin/users/${id}/approvals`,{ecad_code: ecad_code});
  }

  /**
   * Get from the API the URL to show the user personal document
   * 
   * @param {number} id User ORB account ID
   * @param {*} show_back True if should get the back of the document
   * @returns {Promise}
   */
  static getUserPersonalDocument(id, show_back = false) {
    let params = show_back ? "?verse=1" : "";
    return ORBApi._doGet(`/admin/users/${id}/personal_document${params}`);
  }

  // Get Company Document URL
  static getUserCompanyDocument(id) {
    return ORBApi._doGet(`/admin/users/${id}/company_document`);
  }

  /**
   * Update the user association 
   * @param {number} user_id ORB Account ID of the user
   * @param {string} association Association name
   */
  static updateUserAssociation(user_id, association) {
    return ORBApi._doPatch(`/admin/users/${user_id}`,{"association": association})
  }
}
