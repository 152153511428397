import React, { useEffect } from "react";
import {useLocation, useHistory} from 'react-router-dom';
import SessionContext from "../contexts/SessionContext";
import config from "../config";


const Login = ({location}) => {

  let {search} = useLocation();
  let history = useHistory();
  let sessionContext = React.useContext(SessionContext);

  useEffect(() => {

    // Try to get the token from query string or local storage
    let token = new URLSearchParams(search).get("auth_token");
    if (token) {
      window.localStorage.setItem("auth_token", token);
    } else {
      token = window.localStorage.getItem("auth_token");
    }

    // If get the token build the session, otherwise redirect user to Auth App
    if(token) {
      sessionContext.setupSession()
      .then((success) => {
        if (success){
          let initialPage = location.state?.from ? location.state.from : "/tracks";
          history.push(initialPage);
        } else { // there is an expired token 
          window.localStorage.removeItem("auth_token");
          window.location.href = config.orb_auth_url;
        }
      })
    } else {
      window.location.href = config.orb_auth_url;
    }
    
  }, [search, history, sessionContext, location.state?.from]);

  return (<></>)
};

export default Login;