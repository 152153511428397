import React from 'react'
import {BrowserRouter as Router,Route,BrowserRouter, Redirect,Switch } from "react-router-dom";
import SessionContext, { SessionProvider } from '../contexts/SessionContext';

import Login from '../pages/Login'
import TrackList from '../pages/Tracks/List';
import UserList from '../pages/Users/List';


//Creating the Private Route component
const PrivateRoute=({component:Component,...rest})=>{
    let sessionContext = React.useContext(SessionContext);
    return(
        <Route {...rest} render={props=> sessionContext.sessionData ?

            <Component {...props}/>
            :
            <Redirect to={{pathname:'/', state: {from: props.location}}}/>
        }/>
    )
}
const Navigation=()=>(
    <SessionProvider>
    <BrowserRouter>
        <Switch>
            <Router>
                <Route exact path="/" component={Login}/> 
                <PrivateRoute exact path="/tracks" component={TrackList} />
                <PrivateRoute exact path="/users" component={UserList} />
            </Router>
        </Switch>
    </BrowserRouter>
    </SessionProvider>
)

export default Navigation
