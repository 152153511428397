import { ThemeProvider } from '@mui/material/styles';
import Navigation from './routers/Navigation'
import './styles/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import ORBTheme from './styles/ORBTheme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={ORBTheme} >
        <Navigation/>
      </ThemeProvider>
    </div>
  );
}

export default App;
