import { AppBar, Box, Link, Toolbar } from '@mui/material';
import React from 'react';
import { Link as RouteLink, useLocation} from 'react-router-dom';
import ORBLogoWhite from "../assets/white-logo.png";
import {useTheme} from '@mui/material';
import config from '../config';

const ORBAppBar = () => {
	const theme = useTheme();
	const location = useLocation();
	const AppBarStyles = {
		linkItem: {
			paddingLeft: 40,
			textDecoration: 'none',
			color: theme.palette.menu.item,
		},
		menuContainer: {
			flexGrow: 1
		}
	}
	return (
		<Box sx={{flexGrow: 1}}>
			<AppBar position='static'>
				<Toolbar >
					<Box component="img" alt="ISRC App Admin" src={ORBLogoWhite} sx={{width: 40, height: 40}}/>
					<div style={AppBarStyles.menuContainer}>
						<RouteLink to='/tracks' style={location.pathname === '/tracks' ? {...AppBarStyles.linkItem, fontWeight: 'bold'} : AppBarStyles.linkItem}>FONOGRAMAS</RouteLink>
						<RouteLink to='/users' style={location.pathname === '/users' ? {...AppBarStyles.linkItem, fontWeight: 'bold'} : AppBarStyles.linkItem}>USUÁRIOS</RouteLink>
					</div>
					<Link style={AppBarStyles.linkItem} href={config.orb_logout_url}>Sair</Link>
				</Toolbar>
			</AppBar>
		</Box>
	)
}


export default ORBAppBar;