import React from 'react';
import { CircularProgress } from '@mui/material';

const Loading = ({text}) => {

	return (

		<div className='loading-container'>
			<CircularProgress color='secondary'/>
			<div>{text}</div>
		</div>
	)
}

export default Loading