import React, {useState, useEffect} from 'react';
import { Backdrop, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CheckBoxRounded, PersonOffRounded } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import { Link } from 'react-router-dom';

import ORBApi from '../../services/ORBApi';
import ORBAppBar from '../../components/ORBAppBar';
import { ORBTableBodyRow, ORBTableHeaderCell } from '../../components/ORBTable';
import ActionBar from './ActionBar';
import TrackDetails from './Details';
import Loading from '../../components/Loading';

const  TrackList = () => {

	const [tracks, setTracks] = useState({total:0, current_page:1, items:[]});
	const [filters, setFilters] = useState({status:"", searchFilter:"", page:1});
	const [isLoading, setIsLoading] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [selectedTracks, setSelectedTracks] = useState([]);
	const [trackOnPlay, setTrackOnPlay] = useState(null);
	const [showTrackDetails, setShowTrackDetails] = useState(false);

	useEffect(()=> {
		setIsLoading(true);
		ORBApi.loadTracks(filters.searchFilter, filters.status, filters.page)
		.then((data)=> {
			setTracks(data);
			setAllSelected(false);
		})
		.finally(()=> {
			setIsLoading(false);
		});

	}, [filters]);

	const changeAllChecks = () => {
		tracks.items.map((track)=>{
			track.checked = !allSelected;
			return 1;
		})
		setAllSelected(!allSelected);
	}
	const onCheckboxClick = (track_id, checked) => {

		let newTracks = tracks.items;
		let newSelectedTracks = [];
		newTracks.map((track)=> {
			if (track.id === track_id)
				track.checked = checked;
			if (track.checked) newSelectedTracks.push(track.id);
			return 1;
		});
		setTracks({...tracks, items:newTracks});
		setSelectedTracks(newSelectedTracks);
	};

	const viewTrack = (track_id) => {
		setShowTrackDetails(true);
		setTrackOnPlay(track_id);
	}
	return (
		<>
			<ORBAppBar />
			<ActionBar tracksOnPlay={selectedTracks} onFiltersChange={(newFilters) => {setFilters({status:newFilters.status, searchFilter: newFilters.searchText, page:1})}}/>


			{isLoading ? 
				<Loading text="CARREGANDO FAIXAS ..." />
			: 
			<>
				<TableContainer sx={{maxHeight: 500}}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<ORBTableHeaderCell onClick={changeAllChecks}><CheckBoxRounded /></ORBTableHeaderCell>
								<ORBTableHeaderCell>ISRC</ORBTableHeaderCell>
								<ORBTableHeaderCell>Título</ORBTableHeaderCell>
								<ORBTableHeaderCell>Data publicação</ORBTableHeaderCell>
								<ORBTableHeaderCell>Status</ORBTableHeaderCell>
								<ORBTableHeaderCell sx={{textAlign: 'left'}}>Usuário</ORBTableHeaderCell>
								<ORBTableHeaderCell></ORBTableHeaderCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tracks.items.map((track) => {
								if (!track.checked) track.checked = false; // the prop must not be null or undefined to have a controlled control
								return (
								<ORBTableBodyRow key={track.id}>
									<TableCell>
										<Checkbox color="secondary" checked={track.checked} 
											onChange={(event, checked) => {onCheckboxClick(track.id, checked);}}/>
									</TableCell>
									<TableCell sx={{cursor: 'pointer'}} onClick={() => {viewTrack(track.id);}}>{track.isrc}</TableCell>
									<TableCell sx={{cursor: 'pointer'}} onClick={() => {viewTrack(track.id);}}>{track.title}</TableCell>
									<TableCell sx={{cursor: 'pointer'}} onClick={() => {viewTrack(track.id);}}>{track.publish_date}</TableCell>
									<TableCell sx={{cursor: 'pointer'}} onClick={() => {viewTrack(track.id);}}>{track.status}</TableCell>
									<TableCell sx={{cursor: 'pointer', textAlign:'left'}} onClick={() => {viewTrack(track.id);}}>{track.user_name}</TableCell>
									<TableCell >{!track.is_user_active ? <Link to={"/users?name=" + track.user_name} ><PersonOffRounded sx={{cursor:'pointer'}} htmlColor='#FF0000' titleAccess='Usuário ainda não aprovado'/></Link>: null}</TableCell>
								</ORBTableBodyRow>	
								);
							})}

						</TableBody>
					</Table>
				</TableContainer>
				<Pagination count={Math.ceil(tracks.total/10)} page={tracks.current_page}
					onChange={(event, value)=> {setFilters({...filters, page:value})}}/>
			</>
		}
		<Backdrop sx={{color: '#FFF', zIndex: (theme) => theme.zIndex.drawer +1}} open={showTrackDetails} >
			<TrackDetails track_id={trackOnPlay} onClickClose={()=> {setShowTrackDetails(false);}}/>
		</Backdrop>
		</>
	)
}

export default TrackList;