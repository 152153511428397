import { createTheme } from '@mui/material';

const orbCustom = {

	table: {
		headerCell: {
			backgroundColor: '#000000',
			color: "#FFFFFF",
			padding: "16px"
		},
		bodyRow: {
			backgroundColor: '#d9d9d9',
			color: "#000"
		}
	}
}

const orbComponents = {
	MuiContainer: {
		variants: [
			{
				props: {variant: 'modalPage'},
				style: {
					minWidth: '70%',
					height: '80%',
					overflow: 'auto',
					backgroundColor: '#FFF',
					borderRadius: 20
				}
			}
		]
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				textAlign: 'center',
				padding: '0px 16px'
			}
		}
	},
	MuiPagination: {
		styleOverrides: {
			root: {
				display: 'flex',
				marginTop: 20,
				' ul': {
					marginRight: 'auto',
					marginLeft: 'auto'	
				}
			}
		}
	}
}

const ORBTheme = createTheme({
	palette: {
		primary: {
			main: '#000000'
		},
		secondary: {
			main: '#f50057'
		},
		disabled: {
			main: '#C8C6C4'
		},
		menu: {
			item: '#FFFFFF'
		}
	},
	custom: orbCustom,
	components: orbComponents
});

export default ORBTheme;